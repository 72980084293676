/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from "react"

import { Layout, Seo, classes } from "../../layout"

export default function IndexPage(props) {
  const { heading, heading2, section } = classes

  const plugins = [
    {
      comment:
        "Showing content from the landing page of Gol using the default layout",
      content: "/gol",
    },
    {
      comment:
        "Cards-only mode using a light theme with content from the landing page of Gol. Language set to Norwegian.",
      content: "/gol",
      lang: "no",
      mode: "cards,map",
      theme: "light",
    },
    {
      comment:
        "Showing a set of activities as a carousel with a QR code. Can be made fullscreen.",
      content: "8064,6985,6816,8173,8679,7692",
      mode: "carousel",
      delay: 12,
    },
    {
      comment:
        "Loading specific activities by their IDs and showing the full content directly on the page",
      content: "7783,8064",
      lang: "no",
      mode: "full",
    },
  ]

  const [active, setActive] = useState(0)
  function setPlugin(index) {
    if (index !== active) {
      const plugin = document.querySelector('[data-testid="plugin-container"]')
      plugin.innerHTML = pluginHTML(plugins[index])
      setActive(index)
    }
  }

  return (
    <Layout path={props.path}>
      <Seo title="Trailguide plugin" />

      <div className={section + " mt-16 pt-16 mb-16"}>
        <div className="max-w-4xl mx-auto">
          <h1 className={heading}>Trailguide integration solutions</h1>
          <p className="py-2">
            Integrate content from Trailguide onto your website by adding a{" "}
            <code>&lt;trailguide-plugin&gt;</code> element on your page.
          </p>
          <p className="py-2">
            The plugin is super fast, light weight, and very flexible.
          </p>
          <p className="py-2">
            Clicking on an activity will open a window showing all available
            information about the tour. The map can be extended to full screen
            by clicking the extend icon in the top-right corner.
          </p>
          <p className="py-2">
            Click the boxes below to see different representations of the plugin
            and how it works (scroll horizontally to releave all).
          </p>
        </div>
      </div>

      <div className="px-4 md:px-16 pb-12">
        <PluginSelector plugins={plugins} active={active} onClick={setPlugin} />

        <div data-testid="plugin-container">
          <trailguide-plugin
            license="6197b300e7f81914e400e3b7"
            content="/gol"
          ></trailguide-plugin>
        </div>
      </div>

      <div className={section + " mt-16 pt-16 mb-16 overflow-x-hidden"}>
        <div className="max-w-4xl mx-auto">
          <h2 className={heading2}>Documentation</h2>
          <div className="py-8">
            <b>
              Step 1) Add <code>&lt;trailguide-plugin/&gt;</code>
            </b>
            <p className="py-4">
              Add the trailguide plugin code snippet to your HTML code where you
              want to integrate the content from Trailguide
            </p>
            <pre className="inline-block bg-blue-900 text-blue-100 cursor-default px-4 py-2 rounded my-4">
              {/* prettier-ignore */}
              {`<trailguide-plugin
  license="your_license_key"
  content="content_query"
  option="value">
</trailguide-plugin>`.trim()}
            </pre>
          </div>

          <div className="py-8">
            <b>Step 2) Add the plugin script</b>
            <p className="py-4">
              Add the following script tag below your{" "}
              <code>&lt;trailguide-plugin/&gt;</code>.<br />
              You only need one of these, no matter how many{" "}
              <code>&lt;trailguide-plugin/&gt;</code> entries you have.
            </p>
            <pre className="hidden sm:inline-block bg-blue-900 text-blue-100 cursor-default px-4 py-2 rounded my-4">
              {/* prettier-ignore */}
              {`<script>
  ((d, s) => {
    let t = d.createElement('script');
    t.type = 'text/javascript';
    t.src = s;
    !d.querySelector(\`[src="\${s}"]\`) && d.querySelector('head').appendChild(t);
  })(document, 'https://trailguide.net/js/plugin.js');
</script>`.trim()}
            </pre>
            <div className="inline-block sm:hidden bg-blue-900 text-blue-100 cursor-default px-4 py-2 rounded my-4">
              {`<script>((d, s) => {
                  let t = d.createElement('script'); t.type = 'text/javascript'; t.src = s;
                  !d.querySelector(\`[src="\${s}"]\`) && d.querySelector('head').appendChild(t);
                  })(document, 'https://trailguide.net/js/plugin.js');
                </script>`}
            </div>
            <p className="mt-4 py-4">
              <b>Or</b> add the following between the <code>&lt;head&gt;</code>{" "}
              and <code>&lt;/head&gt;</code> section of your HTML code
            </p>
            <pre className="hidden sm:inline-block bg-blue-900 text-blue-100 cursor-default px-4 py-2 rounded my-4">
              {`<script async src="https://trailguide.net/js/plugin.js" />`}
            </pre>
            <div className="inline-block sm:hidden bg-blue-900 text-blue-100 cursor-default px-4 py-2 rounded my-4">
              {`<script async src="https://trailguide.net/js/plugin.js" />`}
            </div>
          </div>

          <div className="py-8">
            <h3>Content</h3>
            <p className="my-4 py-4">
              The <code>content</code> parameter specifies the activities that
              will be loaded and can take several forms. The plugin will only
              load activities you have licensed access to. If you specify
              content you do not have access to, nothing will show.
            </p>
            <div className="my-4 px-4 py-2 bg-gray-300 rounded">
              <table>
                <tbody>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      content="/x49"
                    </td>
                    <td className="px-2 py-1">
                      This will load the content of collection 49
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      content="/hallingdalrides"
                    </td>
                    <td className="px-2 py-1">
                      This will load the same content as on the landing page of
                      hallingdalrides
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      content="8064,6985"
                    </td>
                    <td className="px-2 py-1">
                      This will load activities 8064 and 6985
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      content="lat=62.5&lng=9.6"
                    </td>
                    <td className="px-2 py-1">
                      This will load activities around the specified position
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="py-8">
            <h3>Mode</h3>
            <p className="my-4 py-4">
              The plugin can show the content as cards, as a map, fully
              integrated one-by-on, or as a carousel of images and videos. The
              default mode is maps and cards.
            </p>
            <div className="my-4 px-4 py-2 bg-gray-300 rounded">
              <table>
                <tbody>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      mode="map,cards"
                    </td>
                    <td className="px-2 py-1">
                      Shows the plugin as a map by default but enable the user
                      to switch to card view and back. This is the default mode.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      mode="cards,map"
                    </td>
                    <td className="px-2 py-1">
                      Show the plugin as a set of cards by default but enable
                      the user to switch to map view and back.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      mode="map"
                    </td>
                    <td className="px-2 py-1">
                      This will show the plugin as a map only.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      mode="cards"
                    </td>
                    <td className="px-2 py-1">
                      This will show the plugin as a set of cards only.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      mode="full"
                    </td>
                    <td className="px-2 py-1">
                      This will integrate each and every activity fully into the
                      page.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      mode="carousel"
                      <br />
                      delay="12"
                    </td>
                    <td className="px-2 py-1">
                      This will show the activities one-by-one, showing the
                      cover image (if it has one) or play a video (if it has
                      one). You can set the delay for how long each activity is
                      shown (the default is 15 seconds). A QR-code is shown
                      which can be scanned with your phone and open the activity
                      being on display. You can also make it full-screen.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="py-8">
            <h3>Options</h3>
            <p className="my-4 py-4">
              There are some options you can set to adjust the visual appearance
              of the plugin.
            </p>
            <div className="my-4 px-4 py-2 bg-gray-300 rounded">
              <table>
                <tbody>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">theme="light"</td>
                    <td className="px-2 py-1">
                      Will show the plugin using more white backgrounds and with less bright colors.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">lang="no"</td>
                    <td className="px-2 py-1">
                      Sets the language to Norwegian. This includes both the
                      plugin labels and the content. Possible values are "no",
                      "en", "cs", "pl", "es", "de", "it", and "fr".
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      base="norgeskart"
                    </td>
                    <td className="px-2 py-1">
                      Sets the base map to norgeskart. Possible values are
                      "trailguide", "norgeskart", "mtbmap", "swisstopo",
                      "israel", "google", and "photo"
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      overlays="mtbtracks"
                    </td>
                    <td className="px-2 py-1">
                      Shows the OpenStreetMap MTB tracks. Possible values are
                      "mtbtracks", "cycleroutes", "strava", "stravared", and
                      "protected_areas". They can be combined by separating them
                      with a comma.
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2 py-1 align-top font-bold">
                      list="false"
                    </td>
                    <td className="px-2 py-1">
                      Do not show the list in the map plugin
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function PluginSelector(props = {}) {
  const colors = (index) => {
    return props.active === index
      ? "bg-blue-900 text-blue-100 cursor-default"
      : "bg-gray-200 text-gray-900 cursor-pointer"
  }
  const cursor = (index) => {
    return props.active === index ? "cursor-default" : "cursor-pointer"
  }
  const plugins = props.plugins.map((plugin, index) => {
    const { comment, ...rest } = plugin
    return (
      <div
        key={index}
        className="shadow hover:shadow-lg rounded mx-2"
        onClick={() => props.onClick(index)}
      >
        <pre className={`${colors(index)} px-4 py-2 rounded-t`}>
          {/* prettier-ignore */}
          {`<trailguide-plugin
   license="your_license_key" ${pluginProps(rest)}>
</trailguide-plugin>
`.trim()}
        </pre>
        <div className={`p-3 text-sm bg-white rounded-b ${cursor(index)}`}>
          {comment}
        </div>
      </div>
    )
  })

  return (
    <div className="flex text-xs pb-4 mb-8 overflow-x-scroll">{plugins}</div>
  )
}

function pluginProps(props) {
  return Object.keys(props)
    .map((p) => `\n   ${p}="${props[p]}"`)
    .join("")
}

function pluginHTML(props) {
  return `
    <trailguide-plugin
      license="6197b300e7f81914e400e3b7"
      ${pluginProps(props)}
    ></trailguide-plugin>
  `
}
